import { debounce } from 'lodash';

function saveMergeObject(key: any, info: any) {
  if (!info) {
    return;
  }
  const origin = localStorage.getItem(key);
  if (!origin) {
    localStorage.setItem(key, JSON.stringify(info));
    return;
  }
  localStorage.setItem(
    key,
    JSON.stringify(Object.assign(JSON.parse(origin), info))
  );
}
export function getObject(key: any) {
  const origin = localStorage.getItem(key);
  if (!origin) {
    return {};
  }
  return JSON.parse(origin);
}
export const savePayObject = debounce(saveMergeObject, 1000);
